import { Box, Button, Flex } from '@chakra-ui/core';
import { noop } from 'lodash-es';
import React from 'react';
import { Field, Form } from 'react-final-form';

import {
  composeValidators,
  mustBeValidCardNumber,
  required,
} from '../utils/formValidators';
import CardExpiryField from './CardExpiryField';
import MaskedNumberField from './MaskedNumberField';

const CardDetailsForm = ({ onSubmit = noop, isLoading = false }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Box width={['100%', '100%', '100%', '50%']}>
          <Box mb={4}>
            <Field
              name="primaryAccountNumber"
              label="Card Number"
              component={MaskedNumberField}
              customFormat="#### #### #### ####"
              validate={composeValidators(required, mustBeValidCardNumber)}
            />
          </Box>
          <Flex mx={-4} flexDirection={['column', 'column', 'row', 'row']}>
            <Box
              px={4}
              width={['100%', '100%', '100%', '50%']}
              mb={[4, 4, 0, 0]}
            >
              <CardExpiryField />
            </Box>
            <Box px={4} width={['100%', '100%', '100%', '50%']}>
              <Field
                name="cardSecurityCode"
                label="Security Code"
                component={MaskedNumberField}
                customFormat="####"
                validate={composeValidators(required)}
              />
            </Box>
          </Flex>
          <Box mt={8}>
            <Button
              type="submit"
              size="lg"
              variantColor="purple"
              isLoading={isLoading}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </form>
    )}
  />
);

export default CardDetailsForm;
