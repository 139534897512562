import {
  Tab as ChakraTab,
  Heading,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import CardDetailsSection from '../common/components/CardDetailsSection';
import Loader from '../common/components/Loader';
import { getIsLoading, getSrcProfile } from '../slice';
import ReturningUserForm from './components/ReturningUserForm';

const Tab = styled(ChakraTab)`
  &:focus {
    box-shadow: none;
  }
`;

const Home = () => {
  const isLoading = useSelector(getIsLoading);
  const srcProfile = useSelector(getSrcProfile);

  if (isLoading) {
    return <Loader />;
  }

  if (srcProfile) {
    return <Redirect to="/choose-card" />;
  }

  return (
    <Fragment>
      <Heading textAlign="center" fontSize="md" mt={8} mb={4}>
        Protect your payment information
      </Heading>
      <Text textAlign="center" mb={8}>
        The easy way to pay online
      </Text>
      <Tabs isFitted variantColor="purple">
        <TabList mb={8}>
          <Tab style={{ '&:focus': { boxShadow: 'none' } }}>New User</Tab>
          <Tab>Returning User</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CardDetailsSection />
          </TabPanel>
          <TabPanel>
            <ReturningUserForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Fragment>
  );
};

export default Home;
