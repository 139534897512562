import React from 'react';
import { Field } from 'react-final-form';

import {
  composeValidators,
  mustBeValidExpiry,
  required,
} from '../utils/formValidators';
import MaskedNumberField from './MaskedNumberField';

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01';
    } else if (val > max) {
      val = max;
    }
  }

  return val;
};

const cardExpiry = (val = '') => {
  let month = limit(val.substring(0, 2), '12');
  let year = val.substring(2, 6);

  return month + (year.length ? '/' + year : '');
};

const CardExpiryField = () => (
  <Field
    name="cardExpiry"
    label="Expiry"
    component={MaskedNumberField}
    customFormat="##/####"
    customMask={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
    format={cardExpiry}
    validate={composeValidators(required, mustBeValidExpiry)}
  />
);

export default CardExpiryField;
