import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Image,
  PseudoBox,
  Text,
} from '@chakra-ui/core';
import { isEmpty } from 'lodash-es';
import React, { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { getCards } from '../slice';

const ChooseCard = () => {
  const cards = useSelector(getCards);

  const onChooseCard = useCallback((card) => {
    const payload = {
      type: 'chooseCard',
      payload: { card },
    };
    window.parent.postMessage(JSON.stringify(payload), '*');
  }, []);

  return (
    <Fragment>
      <Heading fontSize="md" my={8}>
        Choose a card
      </Heading>
      {cards.map((card) => (
        <PseudoBox
          as={Flex}
          _hover={{
            bg: '#f2f2f2',
          }}
          key={card.srcDigitalCardId}
          alignItems="center"
          style={{ cursor: 'pointer' }}
          py={4}
          my={8}
          onClick={() => onChooseCard(card)}
        >
          <Box mr={4}>
            <Image
              src={
                process.env.REACT_APP_CARD_PLACEHOLDER ||
                card.digitalCardData.artUri
              }
              w={24}
            />
          </Box>
          <Box>
            <Text>Discover</Text>
            <Text>**** {card.panLastFour}</Text>
          </Box>
          <Box ml="auto">
            <Icon name="chevron-right" size={8} />
          </Box>
        </PseudoBox>
      ))}
      {isEmpty(cards) && <Text>No cards found</Text>}
      <Divider my={8} />
      <Button
        as={NavLink}
        to="/add-card"
        size="lg"
        leftIcon="add"
        variantColor="purple"
      >
        Add new
      </Button>
    </Fragment>
  );
};

export default ChooseCard;
