import jose from 'node-jose';

export const encryptCard = async (payload, publicKey) => {
  if (!publicKey) {
    throw new Error('No key found');
  }
  const keystore = jose.JWK.createKeyStore();
  const key = await keystore.add(JSON.stringify(publicKey));
  const encryptedCard = await jose.JWE.createEncrypt(
    {
      format: 'compact',
      fields: {
        typ: 'JWE',
      },
    },
    key
  )
    .update(Buffer.from(JSON.stringify(payload)))
    .final();
  return encryptedCard;
};
