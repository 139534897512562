import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash-es';

const initialState = {
  isLoading: true,
  srcProfile: null,
  key: null,
  enrollCard: {
    success: null,
    error: null,
    isLoading: false,
  },
  identityLookup: {
    success: null,
    error: null,
    isLoading: false,
  },
  initiateIdentityValidation: {
    success: null,
    error: null,
    isLoading: true,
  },
  completeIdentityValidation: {
    success: null,
    error: null,
    isLoading: false,
  },
};

const slice = createSlice({
  name: 'srci',
  initialState,
  reducers: {
    init(state, action) {
      state.isLoading = false;
      state.srcProfile = action.payload.srcProfile;
      state.key = action.payload.key;
    },
    enrollCardRequest(state) {
      state.enrollCard.isLoading = true;
    },
    enrollCardSuccess(state, action) {
      state.enrollCard.isLoading = false;
      state.enrollCard.success = action.payload;
      state.enrollCard.error = null;
    },
    enrollCardFailure(state, action) {
      state.enrollCard.isLoading = false;
      state.enrollCard.success = null;
      state.enrollCard.error = action.payload;
    },
    identityLookupRequest(state) {
      state.identityLookup.isLoading = true;
    },
    identityLookupSuccess(state, action) {
      state.identityLookup.isLoading = false;
      state.identityLookup.success = action.payload;
      state.identityLookup.error = null;
    },
    identityLookupFailure(state, action) {
      state.identityLookup.isLoading = false;
      state.identityLookup.success = null;
      state.identityLookup.error = action.payload;
    },
    initiateIdentityValidationRequest(state) {
      state.initiateIdentityValidation.isLoading = true;
    },
    initiateIdentityValidationSuccess(state, action) {
      state.initiateIdentityValidation.isLoading = false;
      state.initiateIdentityValidation.success = action.payload;
      state.initiateIdentityValidation.error = null;
    },
    initiateIdentityValidationFailure(state, action) {
      state.initiateIdentityValidation.isLoading = false;
      state.initiateIdentityValidation.error = action.payload;
      state.initiateIdentityValidation.success = null;
    },
    completeIdentityValidationRequest(state) {
      state.completeIdentityValidation.isLoading = true;
    },
    completeIdentityValidationSuccess(state, action) {
      state.completeIdentityValidation.isLoading = false;
      state.completeIdentityValidation.success = action.payload;
      state.completeIdentityValidation.error = null;
      state.srcProfile = action.payload;
    },
    completeIdentityValidationFailure(state, action) {
      state.completeIdentityValidation.isLoading = false;
      state.completeIdentityValidation.error = action.payload;
      state.completeIdentityValidation.success = null;
    },
  },
});

export const {
  enrollCardRequest,
  identityLookupRequest,
  initiateIdentityValidationRequest,
  completeIdentityValidationRequest,
} = slice.actions;

export default slice.reducer;

export const getIsLoading = (state) => get(state, 'isLoading');

export const getSrcProfile = (state) => get(state, 'srcProfile');

export const getEnrollCard = (state) => get(state, 'enrollCard', {});

export const getKey = (state) => get(state, 'key', null);

export const getIdentityLookup = (state) => get(state, 'identityLookup', {});

export const getInitiateIdentityValidation = (state) =>
  get(state, 'initiateIdentityValidation', {});

export const getCompleteIdentityValidation = (state) =>
  state.completeIdentityValidation;

export const getCards = (state) =>
  get(state, 'srcProfile.profiles[0].maskedCards', []);
