import { Alert, AlertIcon, Box, Button, Heading, Link } from '@chakra-ui/core';
import React, { Fragment, useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from '../common/components/Loader';
import MaskedNumberField from '../common/components/MaskedNumberField';
import { required } from '../common/utils/formValidators';
import {
  completeIdentityValidationRequest,
  getCompleteIdentityValidation,
  getInitiateIdentityValidation,
  initiateIdentityValidationRequest,
} from '../slice';

const ConfirmUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initiateIdentityValidation = useSelector(getInitiateIdentityValidation);
  const completeIdentityValidation = useSelector(getCompleteIdentityValidation);

  const onSubmit = useCallback(
    (values) => {
      dispatch(completeIdentityValidationRequest());
      const payload = {
        type: 'srciCompleteIdentityValidation',
        payload: values.otp,
      };
      window.parent.postMessage(JSON.stringify(payload), '*');
    },
    [dispatch]
  );

  const onInitiateIdentityValidation = useCallback(() => {
    dispatch(initiateIdentityValidationRequest());
    const payload = { type: 'srciInitiateIdentityValidation' };
    window.parent.postMessage(JSON.stringify(payload), '*');
  }, [dispatch]);

  useEffect(() => {
    onInitiateIdentityValidation();
  }, [onInitiateIdentityValidation]);

  useEffect(() => {
    if (completeIdentityValidation.success) {
      history.push('/choose-card');
    }
  }, [completeIdentityValidation.success, history]);

  if (initiateIdentityValidation.isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Heading fontSize="md" my={8}>
        Enter the code sent to your email
      </Heading>
      <Box width={['100%', '100%', '100%', '50%']}>
        {(initiateIdentityValidation.error ||
          completeIdentityValidation.error) && (
          <Alert status="error" mb={8}>
            <AlertIcon />
            {initiateIdentityValidation.error}{' '}
            {completeIdentityValidation.error}
          </Alert>
        )}
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="otp"
                component={MaskedNumberField}
                customFormat="# # # # # #"
                validate={required}
              />
              <Link color="purple.500" onClick={onInitiateIdentityValidation}>
                Resend OTP
              </Link>
              <Box mt={8}>
                <Button
                  size="lg"
                  type="submit"
                  variantColor="purple"
                  isLoading={completeIdentityValidation.isLoading}
                >
                  Continue
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
    </Fragment>
  );
};

export default ConfirmUser;
