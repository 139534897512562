import { Alert, AlertIcon, Box, Heading } from '@chakra-ui/core';
import { pick } from 'lodash-es';
import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { enrollCardRequest, getEnrollCard, getKey } from '../../slice';
import { encryptCard } from '../../utils';
import CardDetailsForm from './CardDetailsForm';

const CardDetailsSection = () => {
  const dispatch = useDispatch();
  const enrollCard = useSelector(getEnrollCard);
  const key = useSelector(getKey);

  const onSubmit = useCallback(
    async (values) => {
      dispatch(enrollCardRequest());
      const { cardExpiry } = values;
      const panExpirationMonth = cardExpiry.substring(0, 2);
      const panExpirationYear = cardExpiry.substring(2, 6);
      const encryptedCard = await encryptCard(
        {
          ...pick(values, ['primaryAccountNumber', 'cardSecurityCode']),
          panExpirationMonth,
          panExpirationYear,
        },
        key
      );
      const payload = {
        type: 'enrollCard',
        payload: encryptedCard,
      };
      window.parent.postMessage(JSON.stringify(payload), '*');
    },
    [dispatch, key]
  );

  return (
    <Fragment>
      <Heading fontSize="md" mb={8}>
        Enter your card details
      </Heading>
      {enrollCard.error && (
        <Box width={['100%', '100%', '100%', '50%']} mb={8}>
          <Alert status="error">
            <AlertIcon />
            {enrollCard.error}
          </Alert>
        </Box>
      )}
      <CardDetailsForm isLoading={enrollCard.isLoading} onSubmit={onSubmit} />
    </Fragment>
  );
};

export default CardDetailsSection;
