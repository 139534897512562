import { Divider, Flex, Icon, Image } from '@chakra-ui/core';
import React, { Fragment } from 'react';

const Header = () => (
  <Fragment>
    <Flex alignItems="center">
      <Image src="/images/logo.png" width={8} mr="auto" />
      <Image src="/images/paymentIcons.png" width="200px" />
      <Icon
        ml="auto"
        name="close"
        size={4}
        cursor="pointer"
        onClick={() => {
          const payload = { type: 'cancel' };
          window.parent.postMessage(JSON.stringify(payload), '*');
        }}
      />
    </Flex>
    <Divider my={4} />
  </Fragment>
);

export default Header;
