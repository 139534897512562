import { Alert, AlertIcon, Box, Button, Heading } from '@chakra-ui/core';
import React, { useCallback, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TextField from '../../common/components/TextField';
import {
  composeValidators,
  mustBeValidEmail,
  required,
} from '../../common/utils/formValidators';
import { identityLookupRequest, getIdentityLookup } from '../../slice';

const ReturningUserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const identityLookup = useSelector(getIdentityLookup);

  const onSubmit = useCallback(
    (values) => {
      dispatch(identityLookupRequest());
      const payload = {
        type: 'identityLookup',
        payload: values.email,
      };
      window.parent.postMessage(JSON.stringify(payload), '*');
    },
    [dispatch]
  );

  useEffect(() => {
    if (identityLookup.success) {
      history.push('/confirm-user');
    }
    return () => {};
  }, [history, identityLookup.success]);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Heading fontSize="md" mb={8}>
            Enter your user ID
          </Heading>
          <Box width={['100%', '100%', '100%', '50%']}>
            {identityLookup.error && (
              <Box mb={8}>
                <Alert status="error">
                  <AlertIcon />
                  {identityLookup.error}
                </Alert>
              </Box>
            )}
            <Field
              name="email"
              type="email"
              label="Email"
              component={TextField}
              validate={composeValidators(required, mustBeValidEmail)}
            />
            <Box mt={8}>
              <Button
                type="submit"
                size="lg"
                variantColor="purple"
                isLoading={identityLookup.isLoading}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default ReturningUserForm;
