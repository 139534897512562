import { isNil } from 'lodash-es';
import validator from 'validator';

const valid = require('card-validator');

export const composeValidators = (...validators) => (value, values) =>
  validators.reduce(
    (error, _validator) => error || _validator(value, values),
    undefined
  );

export const required = (value) => (!isNil(value) ? undefined : 'Required');

export const mustBeValidEmail = (value) =>
  validator.isEmail(value) ? undefined : 'Invalid email';

export const mustBeValidCardNumber = (value) => {
  const { isValid } = valid.number(value);
  return isValid ? undefined : 'Invalid card number';
};

export const mustBeValidExpiry = (value) => {
  const { isValid } = valid.expirationDate(value);
  return isValid && value.length === 6 ? undefined : 'Invalid expiry';
};

export const mustBeValidSecurityCode = (value) => {
  const { isValid } = valid.cvv(value);
  return isValid ? undefined : 'Invalid security code';
};
