import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AddCard from './addCard';
import Global from './app/components/Global';
import Header from './app/components/Header';
import theme from './app/theme';
import ChooseCard from './chooseCard';
import ConfirmUser from './confirmUser';
import Home from './home';
import store from './store';

const App = () => {
  useEffect(() => {
    const handleMessage = (e) => {
      try {
        const action = JSON.parse(e.data);
        store.dispatch(action);
      } catch (e) {}
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener(handleMessage);
    };
  }, []);

  useEffect(() => {
    const payload = {
      type: 'srciInit',
    };
    window.parent.postMessage(JSON.stringify(payload), '*');
    return () => {};
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <Global />
        <Router>
          <div>
            <Header />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/confirm-user">
                <ConfirmUser />
              </Route>
              <Route exact path="/choose-card">
                <ChooseCard />
              </Route>
              <Route exact path="/add-card">
                <AddCard />
              </Route>
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
